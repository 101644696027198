import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://d880d0fb97f04ebba1e36265b46dc38f@o617827.ingest.sentry.io/5808847",
  release: "a34880deb17f3df11146af8b6fa1f5b6376a7d2f",
  environment: "staging",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
